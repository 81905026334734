<div style="background-color: black; padding-top: 130px;">
    <div>
        <div class="row" style="background-color: white;">
            <div class="team-area ptb-100">
                <div class="container" style="max-width: 1000px;">
                    <div style="text-align: center;"><img src="/assets/img/sponsors/Dutta-Corp.png" alt=""></div>
                    <div>
                        <div class="wpb_text_column wpb_content_element text-center">
                            <p class="nav-link" (click)="onClick('100')"> $25,000 1.0m Championship</p>
                            <p class="nav-link" (click)="onClick('115')">$75,000 1.15m Championship</p>
                            <p class="nav-link" (click)="onClick('125')">$100,000 1.25m Championship</p>
                            <p class="nav-link" (click)="onClick('135')">$100,000 1.35m Championship
                            </p>
                            <p style="text-align: center;">Entry Fee- $0 </p>
                        </div>

                        <div id="100" class="why-we-different-content mt-4 mb-4">
                            <h3>$25,000 1.0m Championship</h3>
                            <ul>
                                <li><i class="fas fa-check"></i>1.0m in height, Class 1 is a Table II, Class 2 is Table
                                    II.2.a. Only clear rounds
                                    from Class 1 and pre qualified (Bonus) riders will be eligible for Class 2. Each
                                    rider may compete on one horse. Horse/rider combination must compete in one other
                                    class at Split Rock Sarasota. Horse may not have competed above 1.10m in the last
                                    60 days. 24 Hour Rule will be in effect, check in to be announced at the show and
                                    horses must be on site by noon on Friday 1/27.</li>
                                <li><i class="fas fa-check"></i> Eligibility (1.0m): Open to
                                    Professionals, Juniors, and Amateurs. Riders must have competed, in any
                                    jumper class 1.10m and below, at 3 or more Split Rock shows to be eligible, not
                                    including Split Rock Sarasota. If rider has competed above 1.10m at any Split
                                    Rock event, they will not be eligible to compete in the 1.0m Championship. Horses
                                    do not have to qualify. Riders may not cross enter into any other Championship
                                    level.</li>
                                <li><i class="fas fa-check"></i>1.0m in height, Class 1 is a Table II, Class 2 is Table
                                    II.2.a. Only clear rounds
                                    from Class 1 and pre qualified (Bonus) riders will be eligible for Class 2. Each
                                    rider may compete on one horse. Horse/rider combination must compete in one other
                                    class at Split Rock Sarasota. Horse may not have competed above 1.10m in the last
                                    60 days. 24 Hour Rule will be in effect, check in to be announced at the show and
                                    horses must be on site by noon on Friday 1/27.</li>


                                <li><i class="fas fa-check"></i>Eligibility (1.0m): Open to
                                    Professionals, Juniors, and Amateurs. Riders must have competed, in any
                                    jumper class 1.10m and below, at 3 or more Split Rock shows to be eligible, not
                                    including Split Rock Sarasota. If rider has competed above 1.10m at any Split
                                    Rock event, they will not be eligible to compete in the 1.0m Championship. Horses
                                    do not have to qualify. Riders may not cross enter into any other Championship
                                    level.</li>
                                <li><i class="fas fa-check"></i>BONUS: For 1.0m, if a rider competes in 5 or more Split
                                    Rock events and
                                    earns a clear in any 1.0m or 1.10m class (blue ribbon and table II classes do not
                                    count), that rider will be eligible for Class 2 (not have to compete in Class
                                    1).</li>
                                <li><i class="fas fa-check"></i>1.0m) Prize Money: Total $25,000: 1st – $7500; 2nd –
                                    $5500; 3rd – $3250;
                                    4th – $2000; 5th – $1500; 6th – $1250; 7th – $1000; 8th – $750; 9th – $750; 10th
                                    – $500; 11th – $500; 12th – $500</li>
                            </ul>
                        </div>

                        <div id="115" class="why-we-different-content mt-4 mb-4">
                            <h3>$75,000 1.15m Championship</h3>
                            <ul>
                                <li><i class="fas fa-check"></i>1.15m in height, Class 1 is a Table II, Class 2 is Table
                                    II.2.a. Only clear rounds
                                    from Class 1 and pre qualified (Bonus) riders will be eligible for Class 2. Each
                                    rider may compete on one horse. Horse/rider combination must compete in one other
                                    nominated class at Split Rock Sarasota. Horse may not have competed above 1.25m in
                                    the<br>last 60 days. 24 Hour Rule in effect, check in to be announced at the show
                                    and horses must be on site by noon on Friday 1/27.</li>
                                <li><i class="fas fa-check"></i>Eligibility (1.15m): Open
                                    to Professionals, Juniors, and Amateurs. Riders must have competed, in any
                                    jumper class, at 3 or more Split Rock shows to be eligible, not including Split
                                    Rock Sarasota. Horses do not have to qualify. Riders may cross enter between
                                    heights.</li>
                                <li><i class="fas fa-check"></i>BONUS: For 1.15m, if a rider competes in 5 or more Split
                                    Rock events
                                    and earns a clear round in any nominated class(1.10m or above with prize money),
                                    that rider will be eligible for Class 2 (not have to compete<br>in Class 1).</li>
                                <li><i class="fas fa-check"></i>
                                    1.15m) Prize Money: Total $75,000: 1st – $21,750, 2nd – $15,950, 3rd – $9,425, 4th –
                                    $5,800, 5th – $4,350, 6th – $3,625, 7th – $2,900, 8th – $2,175, 9th – $2,175, 10th
                                </li>
                            </ul>

                        </div>

                        <div id="125" class="why-we-different-content mt-4 mb-4">
                            <h3>$100,000 1.25m Championship</h3>
                            <ul>
                                <li><i class="fas fa-check"></i>1.25m, Competition Over Two Rounds, 25% return for the
                                    second round (For judging
                                    purposes only FEI Jumping Article 273.3.3.1 / 273.2.2 will be used). Judged
                                    against the clock in reverse order of qualification from first round. Each rider
                                    may compete on one horse. Horse/rider combination must compete in one
                                    other nominated class at Split Rock Sarasota. Horse may not have competed above
                                    1.35m in the last 60 days. 24 Hour Rule in effect, check in to be announced at
                                    the show and horses must be on site by noon on Friday 1/27.</li>
                                <li><i class="fas fa-check"></i>Eligibility (1.25m): Open to Professionals, Juniors, and
                                    Amateurs. Riders must have competed, in
                                    any jumper class, at 3 or more Split Rock shows to be eligible, not including
                                    Split Rock Sarasota. Horses do not have to qualify. Riders may cross enter
                                    between heights.</li>
                                <li><i class="fas fa-check"></i>BONUS: For the 1.25m, if a rider competes in 5 or more
                                    Split
                                    Rock events and earns a clear round in any nominated class, that rider will
                                    receive an automatic draw into the last 25% of the order of go for the
                                    first round (if more than 25% qualify for the Bonus, riders will automatically go
                                    into that total final percentage). For example if 30% qualify for the Bonus, then
                                    those riders with automatically receive a draw in the last 30% of the order of
                                    go.</li>
                                <li><i class="fas fa-check"></i>• 1.25m) Prize Money: Total $100,000: 1st – $29,250, 2nd
                                    – $21,450, 3rd –
                                    $12,675, 4th – $7,800, 5th – $5,850, 6th – $4,875, 7th – $3,900, 8th – $2,925,
                                    9th – $2,925, 10th – $1,950, 11th – $1,950, 12th – $1,950 $2,500 will be awarded
                                    to the highest placed Junior or Amateur
                                </li>
                            </ul>
                        </div>
                        <div id="135" class="why-we-different-content mt-4 mb-4">
                            <h3>$100,000 1.35m Championship</h3>
                            <ul>
                                <li><i class="fas fa-check"></i>1.35m, Competition Over Two Rounds, 25% return for the
                                    second round (For judging
                                    purposes only FEI Jumping Article 273.3.3.1 / 273.2.2 will be used). Judged
                                    against the clock in reverse order of qualification from first round. Each rider
                                    may compete on one horse. Horse/rider combination must compete in one
                                    other nominated class at Split Rock Sarasota. Horse may not have competed above
                                    1.45m in the last 60 days. 24 Hour Rule in effect, check in to be announced at
                                    the show and horses must be on site by noon on Friday 1/27.</li>
                                <li><i class="fas fa-check"></i>Eligibility (1.35m): Open to Professionals, Juniors, and
                                    Amateurs. Riders must have competed, in
                                    any jumper class, at 3 or more Split Rock shows to be eligible, not including
                                    Split Rock Sarasota. Horses do not have to qualify. Riders may cross enter
                                    between heights.</li>
                                <li><i class="fas fa-check"></i>BONUS: For the 1.35m levels, if a rider competes in 5 or
                                    more Split Rock events and
                                    earns a clear round in any nominated class, that rider will receive an automatic
                                    draw
                                    into the last 25% of the order of go for the first round (if more than 25% qualify
                                    for the Bonus, riders will automatically go into that total final percentage). For
                                    example if 30% qualify for the Bonus, then those riders with automatically receive a
                                    draw in the last 30% of the order of go.</li>
                                <li><i class="fas fa-check"></i>1.35m) Prize Money: Total $100,000: 1st – $29,250, 2nd –
                                    $21,450, 3rd –
                                    $12,675, 4th – $7,800, 5th – $5,850,<br>6th – $4,875, 7th – $3,900, 8th – $2,925,
                                    9th – $2,925, 10th – $1,950, 11th – $1,950, 12th – $1,950 $2,500 will be<br>awarded
                                    to the highest placed Junior or Amateur.</li>
                            </ul>
                        </div>



                        <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper">
                                <h3>Additional Specs</h3>
                                <p>• Split Rock reserves the right to award up to 10 Wild Card positions per height due
                                    to extenuating participation<br>circumstances. 24 Hour Rule in effect, check in to
                                    be announced at the show.<br>• Must compete at a minimum of three (five for the
                                    BONUS) of the following 2023 Split Rock shows to be<br>eligible for Championships in
                                    Sarasota, FL January 24-28, 2024:<br>1. June 7-11 Lexington, KY<br>2. June 13-18
                                    Lexington, KY<br>3. August 2-6 Portland, OR<br>4. August 9-13 Portland OR<br>5.
                                    September 6-10 Paso Robles, CA<br>6. September 27 – October 1 Lexington, KY<br>7.
                                    October 4-8 Lexington, KY<br>8. November 8-12 Aiken, SC<br>9. December 13-17 Fort
                                    Worth, TX</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>