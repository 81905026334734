import { Component, Input, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})


export class CalendarComponent implements OnInit{
  @Input() year: number;
  shows: any;

  
    constructor(private viewportScroller: ViewportScroller,  private firestore: AngularFirestore) {}
  
    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }
  
    ngOnInit() {
      this.getShows(this.year);
    }

    public getShows(year: number) {
      console.log('Getting shows for year: ' + year);
      const startDate = new Date(year+'-01-01');
      const endDate = new Date(year+'-12-31');

      this.shows = this.firestore.collection('concursos', ref => ref
      .where('idOrganizador', '==', '377')
      .where('inicio', '>', startDate)
      .where('inicio', '<=', endDate)
      ).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const inicio = data.inicio.toDate(); // Convert timestamp to Date
          const fin = data.fin.toDate(); // Convert timestamp to Date
          return { ...data, inicio, fin };
        }
      )))
      
    }
}
