
        <div class="row mt-2" style="justify-content: space-evenly;">
            <div
                *ngFor="let file of files"
                class="ml-1 mt-2"
                style="width: auto;"
            >
                <div class="button btn btn-primary">
                  <a href="{{file.link}}" class="color-white">  <i class="fas fa-file-download"></i> {{file.name}}</a>
                </div>
            </div>
        </div>
   