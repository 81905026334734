<div style="background-color: black; padding-top: 130px;">
    <div class="container text-center">
        
        
        <a href="https://www.youtube.com/@splitrockjumpingtour" target="_blank" style="color: white;">
            Click here to watch all streams on the Split Rock Youtube Channel
        </a>

        <!-- <div class="row">
            <iframe class="iframe" src="https://www.youtube.com/embed/OCtL2BC-qIQ?si=AsoCYox45rq-o58i" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </div>
        -->
        <div class="row" style="background-color: rgb(16, 16, 16); margin: 20px auto" *ngFor="let competition of competitions">
            <div >
                <h2 style="color: white;">
                 {{competition.nombre}} - {{ competition.pista}}
                </h2> 
             </div>
      
            <!-- <a href="{{competition.youtubeLink}}" target="_blank">
                <img src="https://img.youtube.com/vi/{{competition.youtubeId}}/hqdefault.jpg" alt="{{competition.nombre}} - {{competition.pista}}">
            </a> -->
            <iframe class="iframe"  [src]="competition.youtubeEmbed" frameborder='0' scrolling="no" allowfullscreen>
            </iframe>

       </div>
       
        <h3 style="color: white;" class="mt-2">Livestreams brought to you by:</h3>
        <img style="max-width: 400px; margin-top: 50px;" src="/assets/img/sponsors/jarvisOfficial.png" alt="C Jarvis">
    </div>
</div>

