export const environment = {
    // ...
    firebase: {
        apiKey: "AIzaSyDVC2Eb_Ieto9Z5yal2yaODUJGI9Ll_NF8",
        authDomain: "ecuestredigital.firebaseapp.com",
        databaseURL: "https://ecuestredigital.firebaseio.com",
        projectId: "ecuestredigital",
        storageBucket: "srjt",
        messagingSenderId: "581815221685",
        appId: "1:581815221685:web:6f8a616998ce947cf56dfc",
        measurementId: "G-X1LF2QG7F2"
    }
  };