import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  public team = [
    {
      name: 'Derek Braun',
      title: 'President, Founder and Manager',
      email: 'derek@splitrockjumpingtour.com',
      picture: 'derek.png'
    },
    {
      name: 'Sami McDermott',
      title: 'COO and Sponsorships',
      email: 'sami@splitrockjumpingtour.com',
      phone: '704-746-2436',
      picture: 'sami.jpeg'
    },
    {
      name: 'Mike Belisle',
      title: 'Event Manager',
      phone: '203-605-1935',
      picture: 'mike.webp'
    },
    {
      name: 'Stephanie Rowney',
      title: 'Event Coordinator & Sponsorhips',
      picture: 'stephanie.jpeg',
      phone: '941-735-3480'
    },
    {
      name: 'Robin Cravens',
      title: 'Business Manager',
      picture: 'robin.webp',
      phone: '859-252-3247',
    },
    {
      name: 'Sophie Plowright',
      title: 'Hiring Manager & Merchandising',
      picture: 'sophie.jpg',
      email: 'sophie@splitrockjumpingtour.com'
    },
    {
      name: 'Michelle Braun',
      title: 'Rider Relations',
      picture: 'michelle.jpeg',
      email: 'michelle@splitrockjumpingtour.com'
    },
    {
      name: 'Matt Thelen',
      title: 'Logistics',
      email: 'matt@splitrockjumpingtour.com',
      picture: 'blank.png'
    },
    {
      name: 'Jessica Rosser',
      title: 'Accounting',
      email: 'accounting@splitrockjumpingtour.com',
      picture: 'blank.png'
    },
    {
      name: 'Linsdey Patt',
      title: 'Office and Rider Relations',
      phone: '828 719-7521',
      email: 'lindsey@splitrockjumpingtour.com',
      picture: 'blank.png'
    },
    {
      name: 'Four Oaks',
      title: 'Media Management & Photography',
      picture: '4oaks.png',
      email: 'callie@fouroakscreative.net'
    },
    {
      name: 'Equestrian Digital',
      title: 'Technology',
      picture: 'ed.png',
      email: 'hello@equestrian.digital'
    },

  ];

    constructor(private viewportScroller: ViewportScroller) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    teamSlides: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='fa fa-angle-left'></i>",
			"<i class='fa fa-angle-right'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 2,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			}
		}
    }

}