import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-location-guide',
  templateUrl: './location-guide.component.html',
  styleUrl: './location-guide.component.scss'
})
export class LocationGuideComponent implements OnInit {
  public showId: any;
  public locationGuide: Array<any> = [];

  constructor(private route: ActivatedRoute, private db: AngularFirestore) {
    this.showId = this.route.snapshot.paramMap.get('showId');

  }

  ngOnInit() {
    this.getLocationGuide();
  }

      // for tab click event
      currentTab = 'all';
      switchTab(event: MouseEvent, tab: string) {
          event.preventDefault();
          this.currentTab = tab;
      }

  public getLocationGuide() {
    this.db.collection('concursos')
      .doc(this.showId)
      .collection('location-guide')
      .valueChanges()
      .subscribe(locationGuide => {
        this.locationGuide = locationGuide;
        console.log('Location guide: ', this.locationGuide);
    }
    );
  }

}
