<div style="background-color: black; padding-top: 130px;">
    <div class="container text-center">
        <div class="row">
            <div class="col-lg-12">
              <div class="card" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0; text-align: center;">
                <img class="card-img-top" *ngIf="competition.sponsorImage" [src]="competition.sponsorImage" style="max-height: 200px; width:fit-content; margin: auto;" >        
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-7" style="text-align: left;">
                      <h1 class="card-title">{{competition?.numero}} - {{competition?.nombre}}</h1>
                      <h2>{{competition?.clave_modalidad}} - {{competition?.nombre_modalidad}}</h2>
                      <h3>Seen: {{competition?.calificados}} / {{competition?.participantes}}</h3>
                      <h4 *ngIf="competition?.hard_start == 1; else rolling">{{competition.inicio * 1000| date:'dd/MMM h:mm a'}}</h4>
                      <ng-template #rolling>
                        <h4>Rolling start after previous class</h4>
                      </ng-template>
                      <h5 *ngIf=" competition?.tiempo_acordado">Time Allowed: {{competition?.tiempo_acordado | number}}</h5>
                      <h5 *ngIf="competition?.tiempo_acordado_2 != '0.00' && competition?.tiempo_acordado_2">Time Allowed 2:
                        {{competition.tiempo_acordado_2}}</h5>
                      <div *ngIf="competition?.message">Mensaje: {{competition?.message}}</div>
      
      
                      <div><a *ngIf="competition?.croquis" href="{{competition?.croquis}}" target="_blank">Course Design</a>
                      </div>
                    </div>
                    <div class="col-xl-5 text-right">
                      <div *ngIf="competition?.youtube_url">
                        <iframe [src]="youtubeEmbed | safe:'url'" frameborder='0' allowfullscreen>
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="competition?.estatus_prueba == '1'">
            <div class="col-12">
              <div class="card" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                <div class="card-title"></div>
                <div class="card-body">
                  <h3 class="header-title mb-3">In Ring</h3>
                  <ng-container *ngFor="let result of results">
                    <ng-container *ngIf="result.en_pista == '1'">
                      <div class="table-responsive" class="inRingData">
                        <table class="table ">
                          <tr>
                            <td>{{result.rankTabla}}</td>
                            <td style="text-align: center;"><img src="{{result.logo_url}}" alt=""
                                style="max-height: 50px; max-width: 50px;"><br>
                              <span class="small">{{result.siglas}}</span>
                            </td>
                            <td  style="text-align: left;">{{result.jinete}}</td>
                            <td>{{result.caballo}} <br> {{result.datosCaballo}}</td>
                            <td>{{result.faltasTabla == '' ? '0' : result.faltasTabla | number}} |
                              {{competition.liveTime}}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </table>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                <div class="card-body">
                  <h3 class="header-title mb-3">Results</h3>
                  <div class="mb-3" *ngIf="categorias.length > 1">
                    <button type="button" class="btn btn-light mr-3" *ngFor="let categoria of categorias" [ngClass]="{'active': categoriaSeleccionada == categoria.value }" (click)="categoriaSeleccionada = categoria.value;">
                      {{ categoria.nombre }}
                    </button>
                  </div>
                  <div class="table-responsive" *ngIf="competition?.id_modalidad != '71'">
                    <table class="table table-striped table-sm">
                      <tbody>
                        <tr *ngFor="let result of filteredResults()">
                          <td>{{(result.rank)}} <br> <span *ngIf="result.startTime"> {{result.startTime}}</span></td>
                          <td style="text-align: center;" class="d-none d-sm-block"> <img src="{{result.riderBadge}}" alt=""
                              style="max-height: 50px; max-width: 50px;"><br>
                            <span class="small">{{result.riderAcronym}}</span>
                          </td>
                          <td><div style="color: black; text-align: left;"
                              title="Rider Profile">{{result.riderName}}</div> </td>
                          <td><div style="color: black; ; text-align: left;">
                            
                            <span *ngIf="result.entryNumber"><strong>{{result.entryNumber }}</strong> </span>
                            
                            {{result.caballo || result.horseName}}</div>
                            <div style="text-align: left;" *ngIf="result.horseInfo && result.horseInfo != ' /  /  /  / '">{{result.horseInfo}}</div></td>
                              
                          <td style="min-width: 100px;">{{result.result1}} <br> {{result.result2}} <br>
                            <span class="small italic">
                              {{result.prize}}
                            </span>
                          </td>
                          <td>
                           
                            <a href="/clip-viewer/{{ encodeClip(result.video1Url) }}"
                              *ngIf="result.video1Url && result.video1 " target="_blank">
                              <i class="fas far fa-play-circle text-success" style="font-size: 1.8em;"></i>
                            </a>
                            <a href="/clip-viewer/{{encodeClip(result.video2Url)}}"
                              *ngIf="result.video2Url && result.video2 " target="_blank">
                              <i class="fas far fa-play-circle text-success" style="font-size: 1.8em; margin-left: 5px;"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
               
                </div>
              </div>
            </div>
      
          </div>
    </div>
</div>