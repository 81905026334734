<div style="background-color: black; height: 130px"></div>
<div style="background-color: white; ">
    <div class="container text-center">
        <div class="row">
            <div class="col-lg-12">
                <h1>Account Deletion</h1>
                <p class="mt-2">
                    If for whatever reason you would like to delete your account for the Split Rock Jumping Tour App,
                    please send an email to the address
                    below.<br>
                    Please note that we might ask for additional information to verify your identity before deleting you
                    account.<br>
                    Please allow up to 7 days for your account to be deleted.<br>
                    The account information that will be deleted is: </p><br>
                <ul>
                    <li>Login information</li>
                    <li>competition entries</li>
                    <li>Any other data that you have entered into the app</li>
                </ul>

                <p>
                    Once completed, you will receive an email confirming that your account has been deleted.</p>


                <p>
                    This action is irreversible and will delete all of your data from our servers.
                </p>
                <p>
                    Account deletion email: <a href="mailto:accountdeletion@vende.io">sraccountdeletion&#64;vende.io</a>
                </p>
            </div>
        </div>
    </div>
</div>