<div style="background-color: black; padding-top: 130px;">
    <div class="container text-center">
        <div class="row">
            <div id="work" class="work-area pt-100 pb-70 bg-light">
                <div class="container">
                    <div class="section-title">
                        <h2>Location <span>Guide</span></h2>
                        <p></p>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="work-tabs">
                        <ul class="nav-tabset">
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'all'}">
                                <span (click)="switchTab($event, 'all')">
                                    All
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'hotels'}">
                                <span (click)="switchTab($event, 'hotels')">
                                    Hotels
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'restaurants'}">
                                <span (click)="switchTab($event, 'restaurants')">
                                    Restaurants
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'rentals'}">
                                <span (click)="switchTab($event, 'rentals')">
                                    Rentals
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'services'}">
                                <span (click)="switchTab($event, 'services')">
                                    Services
                                </span>
                            </li>
                        </ul>
                        <div class="tabs-container">
                            <div class="pane">
                                <div class="row">
                                    <ng-container *ngFor="let location of locationGuide">
                                        <div class="col-md-3 mt-4" *ngIf="location.categoria == currentTab || currentTab == 'all'">
                                            <div class="card" style="width: 18rem;">
                                                <div class="card mb-3" style="width: 18rem;"></div>
                                                <img class="card-img-top" src="{{ location.image }}" alt="Card image cap"
                                                    style="height: 200px; width: 100%; object-fit: contain;">
                                                <div class="card-body">
                                                    <h5 class="card-title" style="height: 2em;">{{ location.name }}</h5>
                                                    <p class="card-text">{{ location.address1 }}</p>
                                                    <p class="card-text">{{ location.address2 }}</p>
                                                    <p class="card-text">{{ location.telephone }}</p>
                                                    <a href="//{{ location.url }}" class="btn btn-primary" target="_blank">Visit
                                                        Website</a>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>