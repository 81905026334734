<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
        <a class="navbar-brand" routerLink="/"
            ><img id="topLogo" height="70px" src="/assets/img/sr/horizontalLogoLight.png" alt=""
        /></a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>4
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="navbar-collapse collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <span class="nav-link"><a routerLink="/">Home</a> </span>
                </li>
                <li class="nav-item">
                    <span class="nav-link">
                        <a routerLink="/livestream">Livestream</a>
                    </span>
                </li>
                <!-- <li class="nav-item">
                    <span class="nav-link">
                        <a routerLink="/forms">Forms</a>
                    </span>
                </li> -->
                <li class="nav-item">
                    <span class="nav-link">
                        <a routerLink="/archive">Archive</a>
                    </span>
                </li>
                <!-- <li class="nav-item"><span class="nav-link" > <a routerLink="/team">The Team</a> </span></li> -->
                <!-- <li class="nav-item">
                    <span class="nav-link">
                        <a routerLink="/forms">Forms</a>
                    </span>
                </li> -->
                <li class="nav-item">
                    <span class="nav-link">
                        <a href="//entries.equestrian.digital" target="_blank"
                            >Online Entries</a
                        >
                    </span>
                </li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('team')">Season</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Livestream</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Championship Series</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Sponsors</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">The Team</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Shop</span></li> -->
            </ul>
            <ul class="navbar-nav for-responsive ml-auto">
                <li class="nav-item">
                    <span class="nav-link" (click)="toggleClass();">
                        <a routerLink="/">Home</a></span
                    >
                </li>
                <li class="nav-item">
                    <span class="nav-link" (click)="toggleClass();">
                        <a routerLink="/livestream">Livestream</a>
                    </span>
                </li>
               
                <li class="nav-item">
                    <span class="nav-link" (click)="toggleClass();">
                        <a routerLink="/archive">Archive</a>
                    </span>
                </li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass();"> <a routerLink="/team">The Team</a> </span></li> -->
                <!-- <li class="nav-item">
                    <span class="nav-link" (click)="toggleClass();">
                        <a routerLink="/forms">Forms</a>
                    </span>
                </li> -->
                <li class="nav-item">
                    <span class="nav-link" (click)="toggleClass();">
                        <a href="//entries.equestrian.digital" target="_blank"
                            >Online Entries</a
                        >
                    </span>
                </li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Season</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Livestream</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('work')">Championship Series</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Sponsors</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('blog')">The Team</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Shop</span></li> -->
            </ul>
        </div>
    </div>
</nav>
