import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { Show } from 'src/interfaces/show.interface';



@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  public showId: any;
  public competitions: any;
  public show: Show;
  public day: any;
  public dayButtons: any;
  announcements: any;
  today: any;

  constructor(private db: AngularFirestore, private route: ActivatedRoute) {
    this.showId = this.route.snapshot.paramMap.get('showId');
    this.competitions = [];
    this.day = '';
    this.dayButtons = '';
  }

  ngOnInit()  {
    this.getShow();
    this.getCompetitions();
    this.today = Date.now();
    this.today = formatDate(this.today, 'yyyy-MM-dd', 'en-US');
    this.getAnnouncements();
  }

  public setDay(day: any) {
    this.day = day;
    // Deactivate buttons

    this.dayButtons = document.getElementsByClassName('dayButton');
    Array.from(this.dayButtons).forEach((element: any) => {
      element.classList.remove('active');
    });
    const dayElement = document.getElementById(day);
    if (dayElement) {
      dayElement.classList.add('active');
    } else {
      this.getFilteredCompetitions();
    }


  }

  private getShow() {
    this.db.collection('concursos').doc(this.showId).valueChanges().subscribe(
      show => {
        this.show = show as Show;
        //Convertir fechas en formato timestamp de firestore a objetos de fecha js
        this.show.inicio = this.show.inicio.toDate();
        this.show.fin = this.show.fin.toDate();
        // Checar si estamos en dia de concurso. Se compara el primer dia del arreglo y el ultimo con la fecha actual
        if (this.today >= this.show.dias[0] && this.today <= this.show.dias.at(-1)) {
          // En caso contrario vamos a seleccionar el dia de hoy
          this.setDay(this.today);
        } else {
          // Si estamos fuera de fechas del evento vamos a seleccionar el primer dia de pruebas
          this.setDay(this.show.dias[0]);
        }

      }
    )
  }

  public getAnnouncements() {
    this.db.collection('concursos').doc(this.showId).collection('avisos', ref => ref.orderBy('created', 'desc')).valueChanges().subscribe(
      announcements => {
        this.announcements = announcements;
      }
    )
  }

  private getCompetitions() {
    this.db.collection('concursos').doc(this.showId).collection('pruebas', ref => ref.orderBy('orden')).valueChanges().subscribe(
      competitions => {
        this.competitions = competitions;
      }
    );
  }

  public getFilteredCompetitions() {
    return this.competitions.filter((competition: any) => {
      return competition.dia == this.day;
    });
  }

}
