import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller) {}

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        let logo = document.getElementById('topLogo') as HTMLImageElement;
        if (scrollPosition >= 50) {
            this.isSticky = true;
            
            logo.src = '/assets/img/sr/horizontalLogo.png';
        } else {
            this.isSticky = false;
            logo.src = '/assets/img/sr/horizontalLogoLight.png';
        }
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {}

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}