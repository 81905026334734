import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-championship',
  standalone: true,
  imports: [],
  templateUrl: './championship.component.html',
  styleUrl: './championship.component.scss'
})
export class ChampionshipComponent {
  constructor(private viewportScroller: ViewportScroller) {}
  
    public onClick(elementId: string): void { 
      let scrollOffset = 130;
      this.viewportScroller.setOffset([0, scrollOffset]);
      this.viewportScroller.scrollToAnchor(elementId);
  }
}
