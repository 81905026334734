import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spotlight',
  templateUrl: './app-spotlight.component.html',
  styleUrls: ['./app-spotlight.component.scss']
})
export class AppSpotlightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
