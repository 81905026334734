<div style="padding-top: 20px; background-color: black;">
    <div class="container">
        <div class="row align-items-center">
            <ng-container *ngFor="let show of shows | async">
                <div class="col-lg-4 col-sm-12" *ngIf="show.banner" >
                    <div class="single-box" style="position: relative; padding: 1px; width: 98%;" >
                        <a href="/show/{{show.id}}">
                            <img src="{{show.banner}}" alt="">
                            <div class="showInfoBanner" style="font-family: 'Copperplate';" >
                                {{show.nombre}} 
                                <span style="float: right;">{{show.inicio | date:'LLL dd' }} - {{show.fin | date:'dd' }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>