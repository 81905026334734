import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CommonModule } from '@angular/common';
import { SafePipe } from '../../safe.pipe';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-livestream',
  templateUrl: './livestream.component.html',
  styleUrl: './livestream.component.scss',
  imports: [CommonModule],
  providers: [SafePipe],
  standalone: true
})
export class LivestreamComponent implements OnInit {
  public showId: any = 'us_487'; // TODO: Cambiar el id del show en automatico
  public competitions: any[];
  public day: any;


  constructor(private db: AngularFirestore, protected sanitizer: DomSanitizer) {
    this.competitions = [];
  }

  ngOnInit() {
    this.getCompetitions();
  }

  public getCompetitions() {
    let livecompetitions = [];
    this.db.collection('concursos').doc(this.showId).collection('pruebas', ref => ref.where('estatus_prueba', '==', '1').orderBy('orden')).get().subscribe(
      competitions => {
      livecompetitions =competitions.docs.map(competition => competition.data());
      livecompetitions = livecompetitions.filter((competition: any) => competition.youtube_url);

      livecompetitions.forEach((competition: any) => {

      console.log('Competition: ', competition);
      if(competition.youtube_url){
        let n = competition['youtube_url'].lastIndexOf('/');
        competition.youtubeId = competition['youtube_url'].substring(n + 1).trim().replace('?feature=share', '').replace('watch?v=', '');
        competition.youtubeEmbed = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + competition.youtubeId);
        this.competitions.push(competition);
      }
    });
    
    // console.log('Competitions: ', this.competitions);


    // this.db.collection('concursos').doc(this.showId).collection('pruebas', ref => ref.where('estatus_prueba', '==', '1').orderBy('orden')).valueChanges().subscribe(
    //   competitions => {
    //     this.competitions = competitions.filter((competition: any) => competition.youtube_url);

    //     this.competitions.forEach((competition: any) => {
    //       let n = competition['youtube_url'].lastIndexOf('/');
    //       competition.youtubeId = competition['youtube_url'].substring(n + 1).trim();
    //       competition.youtubeEmbed = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + competition.youtubeId);
    //     });

    //     console.log(this.competitions)
    //   }
    //);
  }
);

}

}
  

