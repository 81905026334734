import { Component } from '@angular/core';

@Component({
  selector: 'app-longines-banner',
  standalone: false,
  templateUrl: './longines-banner.component.html',
  styleUrl: './longines-banner.component.scss'
})
export class LonginesBannerComponent {

  constructor() { }

  ngOnInit() {
    const script = document.createElement('script');
    script.src = '/assets/longines/lon-livewatch-assets/lon-livewatch.js';
    document.body.appendChild(script);
  }

}
