import { Component } from "@angular/core";

@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrl: "./forms.component.scss",
})
export class FormsComponent {
  public files = [
   
    {
      name: "Photo",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/website%2Ftinywow_2024PhotoForm_49660988.pdf?alt=media&token=85d85a15-e706-44d0-84be-e34f1c309b84",
    },
    {
      name: "Vendor",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/website%2F2024%20Vendor%20App%20Final.pdf?alt=media&token=c93d06e3-a21e-4251-9001-287f1b5ff70e",
    },
    {
      name: "Sponsor",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/website%2Ftinywow_SRJT%202024%20Deck_49666394.pdf?alt=media&token=9eb461b5-163a-4889-8181-02f9f1fb6f53",
    },
    {
      name: "Deposit & Cancellation Policy",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/website%2FSplit%20Rock%20Jumping%20Tour%20Deposit%20and%20Cancellation%20Policy.pdf?alt=media&token=e74accd3-907e-4a1e-a68e-7709fab5d307",
    },
  ];
}
