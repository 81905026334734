<div style="background-color: black; padding-top: 130px;">
    <div class="container text-center">
        <div class="row">
            <div id="team" class="team-area ptb-100">
                <div class="container">
                    <div class="section-title">
                        <h2><span>The Split Rock Team</span></h2>
                    </div>
                    <div class="team-slides">
                        <owl-carousel-o [options]="teamSlides">
                            <ng-template carouselSlide *ngFor="let person of team">
                                <div class="single-team-box">
                                    <div class="image">
                                        <img src="assets/img/sr/team/{{person.picture}}" alt="team-img">
                                    </div>
                                    <div class="content">
                                        <h3 class="title">{{person.name}}</h3>
                                        <span class="post">{{person.title}}</span><br>
                                        <span> <a href="mailto:{{person.email}}">{{person.email}}</a> </span><br>
                                        <span> <a href="tel:{{person.phone}}">{{person.phone}}</a> </span>
                                    </div>
                                    <!-- <div class="social">
                                        <ul>
                                            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                                            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                                        </ul>
                                    </div> -->
                                </div>
                            </ng-template>
                           
                        </owl-carousel-o>
                    </div>
                </div>
                <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
            </div>

        </div>
    </div>
</div>