//import { utf8Encode } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.scss']
})
export class CompetitionComponent implements OnInit {
  public showId: any;
  public competitionId: any;
  public competition: any;
  public results: any;
  public teams: any;
  public youtubeId: any;
  public youtubeEmbed: any;
  public init: boolean;
  public categorias: any[];
  public categoriaSeleccionada: string;

  constructor(private route: ActivatedRoute, private db: AngularFirestore) {
    this.competitionId = this.route.snapshot.paramMap.get('competitionId');
    this.showId = this.route.snapshot.paramMap.get('showId');
    this.results = [];
    this.teams = [];
    this.init = true;
    this.categorias = [];
    this.categoriaSeleccionada = '';
  }

  ngOnInit() {
    this.getCompetition();
    //this.getResults();
  }

  public getCompetition() {
    this.db.collection('concursos').doc(this.showId).collection('pruebas').doc(this.competitionId).valueChanges().subscribe(competition => {
        if(!competition) return;
        if(competition['agrupadores'].length > 1){
          this.categorias = [{ nombre: 'Sin Filtrar', value: '' }, ...competition['agrupadores'].map((a: any) => ({ nombre: a, value: a }))];
        }
        console.log(this.categorias );
        this.competition = competition;
        let n = competition['youtube_url'].lastIndexOf('/');
        this.youtubeId = competition['youtube_url'].substring(n + 1).trim();
        this.youtubeEmbed = ('https://www.youtube.com/embed/' + this.youtubeId).trim();
        if(this.init){
          this.init = false;
          this.getResults();
        }
      }
    );
  }



  public getResults() {
    this.db.collection('results', ref => ref.where("ipc", "==", this.competitionId ).orderBy('order')).valueChanges().subscribe(results => {
      if(this.competition.id_modalidad == '71'){
        this.teams = results.filter((r:any) => r.teamName).sort( (a:any,b:any) => a.rank - b.rank);
      } else {
        this.results = results;
      }
    });
  }

  public encodeClip(string: string) {
    //return btoa(utf8Encode(string).toString());
    return btoa(string);
  }

  public filteredResults(){
    if(!this.categoriaSeleccionada) return this.results;

    return this.results.filter((r: any) => r.riderCategory == this.categoriaSeleccionada);
  }
}
