import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-clip-viewer',
  templateUrl: './clip-viewer.component.html',
  styleUrls: ['./clip-viewer.component.scss']
})
export class ClipViewerComponent implements OnInit {
  public clipId: any;

  constructor(private route: ActivatedRoute) {
    this.clipId = this.route.snapshot.paramMap.get('clipId');
    //this.clipId = this.route.snapshot.queryParams['clip'];

    console.log(this.clipId);
    //this.clipId = this.route.snapshot.paramMap.get('clip');

  }

  ngOnInit() {
  }

  public decode(url){
    return atob(url);
  }

}
