import { Component } from '@angular/core';

@Component({
  selector: 'app-account-deletion',
  standalone: true,
  imports: [],
  templateUrl: './account-deletion.component.html',
  styleUrl: './account-deletion.component.scss'
})
export class AccountDeletionComponent {

}
