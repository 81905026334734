import { Component } from '@angular/core';

@Component({
  selector: 'app-buttons-manager',
  standalone: true,
  imports: [],
  templateUrl: './buttons-manager.component.html',
  styleUrl: './buttons-manager.component.scss'
})
export class ButtonsManagerComponent {

}
