<div style="background-color: black; padding-top: 130px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="sponsorForm" class="needs-validation ng-untouched ng-pristine ng-invalid "
                        (ngSubmit)="addSponsor()">
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group mg-3">
                                    <label for="name">Name:</label>
                                    <input required="true" minlength="3" formControlName="name" maxlength="50" type="text"
                                        class="form-control ng-untouched ng-pristine ng-invalid">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group mg-3">
                                    <label for="url">Website:</label>
                                    <input required="true" minlength="10" formControlName="url" maxlength="550" type="url"
                                        class="form-control ng-untouched ng-pristine ng-invalid">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group mg-3">
                                    <label for="order">Order:</label>
                                    <input required="true" minlength="1" formControlName="order" maxlength="2" type="number"
                                        class="form-control ng-untouched ng-pristine ng-invalid">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mg-3">
                                <label for="logo">Logo:</label>
                                <input type="file" formControlName="img" class="form-control ng-untouched ng-pristine ng-invalid" (change)="onFileSelected($event)"
                                    accept="image/jpeg, image/png">
                                    </div>
                            </div>
                            <!-- <div>
                                <label for="url">Website:</label>
                                <input type="url" formControlName="url" required>
                            </div> -->
                            
                        </div>
        
                        
                        <div class="col-lg-12 col-md-12"><button id="submitBtn" disabled type="submit" class="btn btn-primary">Save</button></div>
                        <div *ngIf="uploading">Saving</div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li *ngFor="let sponsor of sponsors" >
                                    <i class="fas fas far fa-arrow-alt-circle-up" (click)="moveSponsorUp(sponsor)" style="float: left;"></i>
                                    
                                    <span> {{sponsor.order}}  - {{sponsor.name}}</span>
                                    <span>{{sponsor.url}}</span>
                                    <img width="60"  src={{sponsor.img}} alt="">
                                    <i class="fas fa-trash-alt" style="float: right; right: 0; left: inherit;" (click)="deleteSponsor(sponsor)"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        


    </div>


</div>