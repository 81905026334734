import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { filter, finalize } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import 'firebase/compat/firestore';
import { CommonModule } from '@angular/common';

import { Sponsor } from 'src/interfaces/sponsor.interface';

@Component({
  selector: 'app-upload-sponsor',
  templateUrl: './upload-sponsor.component.html',
  styleUrl: './upload-sponsor.component.scss',
  imports: [ReactiveFormsModule, CommonModule], // Add to imports
  standalone: true

})
export class UploadSponsorComponent implements OnInit{
  public logo: string;
  public uploading: boolean = false;
  public sponsors: Array<Sponsor>;

  ngControl: FormControl = new FormControl();

  onInput(value: string) {
    const urlSafeValue = value.replace(/[/\s]/g, '-');
    this.ngControl.setValue(urlSafeValue);
  }

  sponsorForm: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    img: [null, Validators.required],
    order: [0, Validators.required],
    url: ['', Validators.required]
  });

  constructor(
    private db: AngularFirestore, 
    private storage: AngularFireStorage, 
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.sponsorForm = this.formBuilder.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      order: ['', Validators.required],
      img: [Image, Validators.required]
    });
    this.getSponsors();
  }

  onFileSelected(event: Event) {
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    const file = files ? files[0] : null;
    if (file) {
      const imageControl = this.sponsorForm.get('img');
      if (imageControl) {
        imageControl.setValue(file);
        imageControl.updateValueAndValidity();
        const submitBtn = document.getElementById('submitBtn') as HTMLInputElement;
        if (submitBtn) {
          submitBtn.disabled = false;
        }
      }
    }

  }

  public addSponsor() {
    this.uploading = true;
    console.log('Adding Sponsor');
    const formData = this.sponsorForm.value;
    console.log(formData);
    const filePath = `website/sponsors/${formData.img.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, formData.img);


    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(imageUrl => {
          const sponsorItem: Sponsor = {
            name: formData.name,
            order: formData.order,
            url: formData.url,
            img: imageUrl
          };

          try{
            this.db.collection('srjt/sponsors/general').doc(formData.name).set(sponsorItem);
            console.log('File Uploaded');
            this.sponsorForm.reset();
            this.sponsorForm = this.formBuilder.group({
              name: ['', Validators.required],
              url: ['', Validators.required],
              order: ['', Validators.required],
              img: [Image, Validators.required]
            });
            this.uploading = false;

          } catch(e) {
            console.error('Error uploading file', e);
            this.uploading = false;
          }
      });
    
    })).subscribe();


  }

  public getSponsors() {
    console.log("Getting sponsors");
    this.db.collection('srjt/sponsors/general', ref => ref
      .orderBy('order')
    ).valueChanges().subscribe(
      sponsors => {
        console.log(sponsors);
        this.sponsors = sponsors as Array<Sponsor>;
      }
    );
  }

  public deleteSponsor(sponsor: Sponsor) {
    console.log('Deleting sponsor', sponsor);
    this.db.collection('srjt/sponsors/general').doc(sponsor.name).delete().then(
      () => {
        console.log('Sponsor deleted');
      }
    );
  }

  public moveSponsorUp(sponsor: Sponsor) {
    console.log('Moving sponsor up', sponsor);
    const index = this.sponsors.indexOf(sponsor);
    if (index > 0) {
      const newOrder = this.sponsors[index - 1].order;
      this.db.collection('srjt/sponsors/general').doc(sponsor.name).update({order: newOrder});
      this.db.collection('srjt/sponsors/general').doc(this.sponsors[index - 1].name).update({order: sponsor.order});
    }
  }

}
