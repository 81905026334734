<div class="partner-area text-center" style="background: white; padding: 30px">
    <h2 class="text-center mb-2">Thank you to our sponsors</h2>
    <a href="https://luganodiamonds.com/" target="_blank">
        <img class="mt-4" width="500" src="/assets/img/sponsors/luganoLogo.png" alt="" />
    </a>
    <br>
    <a href="https://www.jarvisinsurance.com/" target="_blank">
        <img class="mt-4" width="500" src="/assets/img/sponsors/jarvisHorizontal.png" alt="" />
    </a>
</div>
<div class="partner-area ptb-70 bg-fffdfd">
    <div class="container">
        <!-- <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide *ngFor="let sponsor of sponsors">
                    <div class="partner-item">
                        <a href="{{sponsor.url}}" target="_blank"><img height="230" src="{{sponsor.img}}"
                                alt="partner" /></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div> -->
        <div class="grid">
            <ng-container *ngFor="let sponsor of sponsors">
                <a href="{{sponsor.url}}" target="_blank">
                    <img height="300" src="{{sponsor.img}}" alt="partner" class="bw-image" />
                </a>
            </ng-container>
        </div>
    </div>
</div>